import React, { useState } from "react";
import { Box, Grid, Typography, Pagination } from "@mui/material";
import { useGetData } from "../../hooks";
import { Link, NavLink } from "react-router-dom";
import { ImageList } from "../ImageList";
import "../style.css";
import { formatDate } from "../../utils/formatDate";


const AINews = () => {
  const { loading, data, error } = useGetData("/ai-articles/");
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  if (loading) {
    return "Loading...";
  }

  if (error) {
    return JSON.stringify(error);
  }
  const {
    items,
  } = data;

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = items.slice(startIndex, startIndex + itemsPerPage);
  return (
    <>
    <Box
        className='newAi'
        minHeight={1000}
        height={'auto'}
      >
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <Typography variant="h6" sx={{ textAlign: "left", fontStyle: " italic", fontWeight: '700', pb: 4 }}>NEW ARTIFICIAL INTELLIGENCE</Typography>
            <Box className="news-list-wrapper">
            {paginatedData.map((article, index) => {
              const { title, date_published, url, image, authors } = article;
              const { name } = authors[0];
              return (
                <Box className="news-list">
                  <Grid container spacing={2} sx={{ pb: 3 }} key={index}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <img
                        key={title}
                        alt={title}
                        src={image}
                        className="articleImage"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="content-wrapper">
                      <Typography
                        variant="subtitle1"
                        as={NavLink}
                        to={url}
                        target="_blank"
                        className="title"
                        sx={{ color: "#FFFFFF" }}
                      >
                        {title}
                      </Typography>
                      <Box  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Link to='/' className="category">{name}</Link>
                        <Typography variant="body2" className="postdate" sx={{ color: "#FFFFFF" }}>
                         {formatDate(date_published)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
            </Box>
            <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                count={Math.ceil(items.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7} className="photos-box-wrapper">
            <Typography variant="h6" sx={{ textAlign: "left", fontStyle: " italic", fontWeight: '700', pb: 4 }}>PHOTOS</Typography>
            <ImageList />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AINews;
