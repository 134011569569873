import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import "../style.css";
import { useGetData } from "../../hooks";
import { Link } from "react-router-dom";
import { handleGAEvents } from "../../utils";
import { gaConstants } from "../../constants/gaConstants";

const mockData = [
  {
    image:
      "https://lottolenghi.me/wp-content/uploads/2024/07/phoebe-bacon-wisconsin-2-1024x683-1.jpg",
    url: "https://lottolenghi.me/paris-2024-olympics-swimming-competition-schedule-for-our-top-athletes/",
    title:
      "Paris 2024 Olympics: Swimming Competition Schedule for Our Top Athletes",
      content_text:
      "The excitement for the Paris 2024 Olympics is building, and the swimming events promise to be a highlight. Here’s a detai...",
  },
  {
    image:
      "https://lottolenghi.me/wp-content/uploads/2024/06/shutterstock_2410036433.jpg",
    url: "https://lottolenghi.me/diversity-and-inclusion-creating-an-equitable-workplace/",
    title: "Diversity and Inclusion: Creating an Equitable Workplace",
    content_text:
      "In today’s globalized and interconnected world, diversity and inclusion (D&I) are not just moral imperatives but strateg...",
  },
  {
    image:
      "https://lottolenghi.me/wp-content/uploads/2024/06/shutterstock_2376028571.jpg",
    url: "https://lottolenghi.me/cybersecurity-training-building-a-culture-of-security-awareness/",
    title: "Cybersecurity Training: Building a Culture of Security Awareness",
    content_text:
      "Building a culture of security awareness is crucial for organizations to protect themselves from cyber threats. Employees...",
  },
  {
    image:
      "https://lottolenghi.me/wp-content/uploads/2024/06/shutterstock_2101929301.jpg",
    url: "https://lottolenghi.me/mentorship-and-development-investing-in-your-teams-growth/",
    title: "Mentorship and Development: Investing in Your Team’s Growth",
    content_text:
      "In the competitive world of business, an organization’s strength lies in its people. Investing in the growth and develop...",
  },
];
const Slider = () => {

  const { loading, data } = useGetData("/highlights/");
  console.log(data)

  return (
    <div className="header-top-wrapper">
      {
        loading ? (
          <div style={{ display: 'flex', justifyContent: "center", paddingTop: "3rem" }}>
            <CircularProgress style={{ alignItems: "center" }} />
          </div>
        ) : (
          <Carousel
            showArrows={true}
            autoPlay
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            stopOnHover={true}
            dots={false}
            className="carousel-wrapper"
          >
            {(data?.items || mockData).slice(0, 4).map((sData) => {
              const { title, image, url, content_text } = sData;
              return (
                <div className="imageWrapper">
                  <img src={image} alt={title} />
                  <div className="bannerContent">
                    <Typography variant="h1" component="h1">
                      {title}
                    </Typography>
                    <Typography variant="p" component="p">
                      {content_text}
                    </Typography>
                    <Link to={url} target="_blank" onClick={() => handleGAEvents(gaConstants.readMore)}>
                      READ MORE{" "}
                      <img alt="arrow" src="/images/arrow-down.svg" width={100} />
                    </Link>
                  </div>
                </div>
              );
            })}
          </Carousel>
        )
      }
    </div>
  );
};

export default Slider;
