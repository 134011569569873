import ReactGA from "react-ga4";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { configuration } from "./config";
import { HomePage, Results } from "./pages";

function App() {
  useEffect(() => {
    ReactGA.initialize(configuration.googleAnalyticsKey);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home page" });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/result" element={<Results />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
