import { Grid, List, Typography, Box } from "@mui/material";
import Result from "../../component/ResultPage/Result";
import Videos from "../../component/ResultPage/Videos";
import Sources from "../../component/ResultPage/Sources";
import RelatedContent from "../../component/ResultPage/RelatedContent";
import { Header, Footer } from "../../component";
import { useEffect, useRef, useState } from "react";
import FollowUpQuestion from "../../component/ResultPage/FollowUp";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { configuration } from "../../config";


const { baseUrl } = configuration
const ResultPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    if (location === null || location.state === null) {
        navigate("/")
    }
    const { question } = location?.state || {};
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const [messages, setMessages] = useState([])
    const messageRef = useRef({ question, answer: "" });
    //eslint-disable-next-line
    const [_, setLocal] = useState("");
    const [sessionId, setSessionId] = useState(null)
    const [followUpQues, setFollowUpQues] = useState("")


    useEffect(() => {
        if (
            messageRef?.current?.answer !== undefined
        ) {
            setMessages((prevMessages) => [
                ...prevMessages.slice(0, -1),
                messageRef.current
            ]);
        }
        // eslint-disable-next-line
    }, [messageRef.current]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const fetchChatResults = async (question) => {
        messageRef.current = {
            ...messageRef.current,
            answer: "",
        };
        let contentString;
        try {
            setLoading(true)
            const response = await fetch(
                `${baseUrl}/ask/`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        query: question,
                        ...sessionId ? { session_id: sessionId } : {},
                    })
                }
            );
            let reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = "";
            let isExtra = false
            // let citationAndImages = "";
            setLoading(false)
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    messageRef.current = {
                        ...messageRef.current,
                        answer: "",
                        question: "",
                    };
                    return;
                }
                buffer += decoder.decode(value, { stream: true });
                let endOfChunkIndex;
                contentString = "";
                while ((endOfChunkIndex = buffer.indexOf("\n\n")) > -1) {
                    const chunk = buffer.slice(0, endOfChunkIndex).trim();
                    buffer = buffer.slice(endOfChunkIndex + 2);
                    if (chunk.startsWith("data: ")) {
                        const jsonString = chunk.slice(6);

                        try {
                            const json = JSON.parse(jsonString);

                            if (json && json['session_id']) {
                                let sessionString = json['session_id']
                                    .replace(/'\\n\\n'/g, ":\\n\\n")
                                    .replace(/\\n/g, "\n")
                                    .replace(/'/g, "")
                                    .replace(/"/g, "");
                                setSessionId(sessionString);
                                return;
                            }

                            if (json && json['content']) {
                                contentString = json['content']
                                    .replace(/'\\n\\n'/g, ":\\n\\n")
                                    .replace(/\\n/g, "\n")
                                    .replace(/'/g, "")
                                    .replace(/"/g, "");
                                
                                }
                            if ((messageRef.current.answer + contentString).toLowerCase().includes("citations")) {
                                isExtra = true
                            }
                            
                            if (!isExtra && (contentString !== undefined || contentString.length > 0)) {
                                // eslint-disable-next-line
                                setLocal((prev) => prev + contentString);
                                messageRef.current = {
                                    ...messageRef.current,
                                    question: question,
                                    answer: messageRef.current.answer + contentString,
                                };
                            }
                        } catch (e) {
                            console.error("Failed to parse JSON", e);
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching response from ASK API:");
        }
    }

    const handleSend = (ques) => {
      if (ques) {
        messageRef.current.question = ques
        setMessages([...messages, { question: ques }]);
        setQuery(ques);
        fetchChatResults(ques);
        setFollowUpQues("");
      }
    };


    useEffect(() => {
        handleSend(question);
        //eslint-disable-next-line
    }, []);
    return (
      <>
        <Header />
        <div className="result-wrapper ">
          <Grid container spacing={5}>
            <Grid key="result" item xs={12} md={12} lg={9}>
              <List
                sx={{
                  overflow: "auto",
                  scrollBehavior: "smooth",
                  overflowY: "auto",
                }}
                className="scrollDivRef scroll-container"
              >
                {messages?.map((message, index) => (
                  <>
                    <Typography variant="h5" className="result-title">
                      RESULTS FOR: {message.question}
                    </Typography>
                    {message.answer && (
                      <>
                        <Result
                          key={index}
                          message={message}
                          className="result-list"
                        />
                      </>
                    )}
                  </>
                ))}
                {loading && (
                  <Box className="skeleton-loader-wrapper">
                    <Skeleton className="loader-line" />
                    <Skeleton className="loader-line" />
                    <Skeleton className="loader-line" />
                    <Skeleton className="loader-line" />
                    <Skeleton className="loader-line" />
                    <Skeleton className="loader-line" />
                  </Box>
                )}
              </List>
              <Box className="mobile-input-wrapper">
                <FollowUpQuestion
                  followUpQues={followUpQues}
                  setFollowUpQues={setFollowUpQues}
                  handleSend={handleSend}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={3} className="side-section">
              {query && <Videos />}
              <Sources />
              {query && (
                <RelatedContent input={query} followUpQues={followUpQues} />
              )}
            </Grid>
          </Grid>
          <Box className="desktop-input-wrapper">
            <FollowUpQuestion
              followUpQues={followUpQues}
              setFollowUpQues={setFollowUpQues}
              handleSend={handleSend}
            />
          </Box>
        </div>
        <Footer />
      </>
    );
}

export default ResultPage;