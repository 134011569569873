import * as React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { handleGAEvents } from "../../utils";
import { gaConstants } from "../../constants/gaConstants";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

const socialLinks = [
  { icon: <XIcon />, url: "https://twitter.com/LesOttolenghi", id: 1 },
  { icon: <FacebookIcon />, url: "https://www.facebook.com/LesOttolenghiCIO", id: 2 },
  { icon: <LinkedInIcon />, url: "https://www.linkedin.com/in/lesottolenghi/", id: 3 },
  { icon: <InstagramIcon />, url: "https://www.instagram.com/lesottolenghiofc", id: 4 },
];

const menuLinks = [
  { label: "ABOUT - LES", url: "https://lottolenghi.me/about/", isExternal: true, id: 1 },
  {
    label: "PARIS 2024",
    url: "https://lottolenghi.me/paris-2024/",
    isExternal: true,
    id: 2,
  },
  { label: "ZEN NEST", url: "https://thezennest.com/", isExternal: true, id: 3 },
];

const handleClick = (id) => {
  let gaEvent;
  switch (id) {
    case 1:
      gaEvent = gaConstants.menuLink1;
      break;
    case 2:
      gaEvent = gaConstants.menuLink2;
      break;
    case 3:
      gaEvent = gaConstants.menuLink3;
      break;
    default:
      gaEvent = gaConstants.default;
  }
  handleGAEvents(gaEvent);
};

const handleSocialLinkClick = (id) => {
  const socialLink = socialLinks.find(link => link.id === id);
  const gaEvent = socialLink ? gaConstants[`socialLink${id}`] : gaConstants.default;
  handleGAEvents(gaEvent);
};


function ResponsiveAppBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <AppBar position="static" className="header">
      <CssBaseline />
      <Box>
        <Toolbar disableGutters className="inner-wrapper">
          <Typography
            key="logo-wrapper"
            component={NavLink}
            to="/"
            className="logo-wrapper"
          >
            <img
              alt="Les Ottolenghi"
              key="logo"
              src="https://lottolenghi.me/wp-content/uploads/2024/04/logo-color-black-horizontal.png"
            />
          </Typography>
          <Box key="label" sx={{ flexGrow: 1 }} />
          <IconButton
            sx={{ display: { md: "none" } }}
            onClick={toggleMenu}
            size="large"
            aria-label="menu"
            className="hamburger-icon"
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Box className={`menus-wrapper ${menuOpen ? 'open' : ''}`}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            {menuLinks.map((menu) => {
              const { label, url, isExternal, id } = menu;
              return (
                <IconButton
                  style={{ color: "#000000" }}
                  className="links"
                  as={NavLink}
                  to={url}
                  {...(isExternal ? { target: "_blank" } : {})}
                  size="small"
                  onClick={() => handleClick(id)}
                >
                  {label}
                </IconButton>
              );
            })}
            <Box className="social-links-wrapper">
            {socialLinks.map((social) => {
              const { icon, url, id } = social;
              return (
                <IconButton
                  key={url}
                  as={NavLink}
                  to={url}
                  className="social-links"
                  target="__blank"
                  size="small"
                  sx={{ color: "#000000"}}
                  onClick={() => {
                    handleSocialLinkClick(id)
                  }}
                >
                  {icon}
                </IconButton>
              );
            })}
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
export default ResponsiveAppBar;
