import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography, Pagination } from "@mui/material";
import TabPanel from "./Tabpanel";
import { useGetData } from "../../hooks";
import { NavLink } from "react-router-dom";
import { configuration } from "../../config";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { formatDate } from "../../utils/formatDate";


const FeedsAndPodcasts = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [podcastsData, setPodcastData] = useState({})
  const itemsPerPage = 5;
  const { baseUrl } = configuration;
  const { loading, data, error } = useGetData("/mashable-feed/");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let podcastResponse;
        if (tabIndex === 1) {
          podcastResponse = await fetch(`${baseUrl}/podcasts/`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          let response = await podcastResponse.json();
          setPodcastData(response);
        }
      } catch (err) {
        console.error("Podcast API error", err);
      }
    };

    fetchData();
  }, [tabIndex, baseUrl]);


  if (loading) {
    return "Loading...";
  }

  if (error) {
    return JSON.stringify(error);
  }
  const {
    rss: {
      channel: { item },
    },
  } = data;

  const handleTabChange = async (event, newValue) => {
    setTabIndex(newValue);
    setPage(1)
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = item.slice(startIndex, startIndex + itemsPerPage);
  const {
    rss: {
      channel: { item: podcasts = [] } = {},
    } = {},
  } = podcastsData;

  const paginatedPodcasts = podcasts.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
     <Box
        className="feeds"
      >
        <Typography variant="h5" sx={{ textAlign: "left", fontStyle: " italic", fontWeight: '700', pb: 4 }}>
          MASHABLE FEEDS & PODCAST'S
        </Typography>

        <Tabs
          className="podcast-tabs"
          value={tabIndex}
          onChange={handleTabChange}
          centered
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs example"
        >
          <Tab label="MASHABLE FEED" />
          <Tab label="PODCAST" />
        </Tabs>
        <TabPanel className="feeds-tab-wrapper" value={tabIndex} index={0}>
          {paginatedData.map((article) => {
            const { title, pubDate, link, enclosure } = article;
            const date=formatDate(pubDate)
            const imageUrl = enclosure["@url"];
            return (
              <Box className="podcast-card" gap={1} mb={3}>
                <img
                  className="feed-image"
                  alt={title}
                  src={imageUrl}
                />
                <Box className="feed-content">
                  <Typography
                    variant="subtitle1"
                    as={NavLink}
                    to={link}
                    target="_blank"
                    color="#FFFFFF"
                  >
                    {title.substr(0, 100)}...
                  </Typography>
                  <Typography variant="body2" color="#FFFFFF" className="feed-date">
                    {date}
                  </Typography>
                  <Box sx={{ textAlign: 'right' }}
                    as={NavLink}
                    to={link}
                    target="_blank"
                  >

                    <KeyboardArrowRightIcon
                      sx={{ fill: '#ffff', position: 'absolute', right: '19px', bottom: '24px' }}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </TabPanel>
        <TabPanel  value={tabIndex} index={1}>
          {paginatedPodcasts.map((article) => {
            const { title, pubDate, link, "media:content": { "@url": imageUrl = "" } = {} } = article;
            return (
              <Box className="podcast-card" display="flex" gap={1} mb={2}>
                <img
                  className="feed-image"
                  alt={title}
                  src={imageUrl}

                />
                <Box className="feed-content">
                  <Typography
                    variant="subtitle1"
                    as={NavLink}
                    to={link}
                    target="_blank"
                    color="#FFFFFF"
                  >
                    {title.substr(0, 100)}...
                  </Typography>
                  <Typography variant="body2" color="#FFFFFF" className="feed-date">
                    {pubDate}
                  </Typography>
                  <Box sx={{ textAlign: 'right' }}
                    as={NavLink}
                    to={link}
                    target="_blank"
                  >

                    <KeyboardArrowRightIcon
                      sx={{ fill: '#ffff', position: 'absolute', right: '19px', bottom: '24px' }}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </TabPanel>
        {/* <TabPanel value={tabIndex} index={1}>
                <Typography variant="h6">Tab 2 Content</Typography>
                <Typography>This is the content of Tab 2.</Typography>
            </TabPanel> */}
        <Box mt={2} sx={{ display: "flex", justifyContent: "center" }} className="pagination-nav sx-pagination">
          <Pagination
            sx={{ mt: 1 }}
            className="paginations "
            count={Math.ceil(item.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </Box>
    </>
  );
};
export default FeedsAndPodcasts;
