import React from "react";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const Sources = React.memo(() => {
  const mockData = [
    {
      logo: "/images/source1.png",
      name: "Paris 2024 Olympic Sports",
      url: "https://edition.cnn.com/sport/live-news/paris-olympics-2024-opening-ceremony-seine/index.html",
    },
    {
      logo: "/images/source2.png",
      name: "Paris 2024 Olympic Latest News Schedules",
      url: "https://olympics.com/en/paris-2024",
    },
  ];
  return (
    <>
      <Box className="sources-wrapper">
        <Typography variant="h5" className="sub-title">
          SOURCES
        </Typography>
        {mockData.map((data) => (
          <Box
            style={{ textDecoration: "none" }}
            as={NavLink}
            to={data.url}
            target="_blank"
            className="box-wrapper"
          >
            <img src={data.logo} alt={data.name} />
            <Box>
              <Typography variant="subtitle1">{data.name}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
});
export default Sources;
