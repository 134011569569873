const FAQ = [
  "When are the Paris 2024 Olympics scheduled to take place?",
  "What are the official dates for the Paris 2024 Olympics opening and closing ceremonies?",
  "How can I purchase tickets for the Paris 2024 Olympics?",
  "What sports will be featured in the Paris 2024 Olympics?",
  "What new sports or events are being introduced in the Paris 2024 Olympics?",
  "Where will the various Olympic events be held in Paris?",
  "How can I volunteer for the Paris 2024 Olympics?",
  "What are the COVID-19 safety measures for the Paris 2024 Olympics?",
  "How can I watch the Paris 2024 Olympics online or on TV?",
  "What is the mascot for the Paris 2024 Olympics?",
  "Are there any special accommodation packages for visitors to the Paris 2024 Olympics?",
  "How can I get to the Olympic venues using public transportation?",
  "What are the best tourist attractions to visit in Paris during the Olympics?",
  "What are the security measures in place for the Paris 2024 Olympics?",
  "Can I bring food and drinks into the Olympic venues?",
  "How can I find information about specific athletes competing in the Paris 2024 Olympics?",
  "What are the rules for bringing cameras and other recording devices into the venues?",
  "Where can I find official Paris 2024 Olympics merchandise?",
  "What are the environmental sustainability initiatives for the Paris 2024 Olympics?",
  "How can I stay updated with the latest news and results from the Paris 2024 Olympics?",
];

export const getRandomPrompts = () => {
    let shuffled = FAQ.sort(() => 0.5 - Math.random());
    // Get sub-array of first n elements after shuffled
    return shuffled.slice(0, 4);
}
