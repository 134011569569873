import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useGetData } from "../../hooks";

const socialLinks = [
  {
    icon: <XIcon />,
    url: "https://twitter.com/LesOttolenghi/",
    alt: "twitter",
  },
  {
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/LesOttolenghiCIO/",
    alt: "facebook",
  },
  {
    icon: <LinkedInIcon />,
    url: "https://www.linkedin.com/in/lesottolenghi/",
    alt: "linkedin",
  },
  {
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/lesottolenghiofc/",
    alt: "instagram",
  },
];
const Footer = () => {
  const formContainerRef = useRef(null);
  const { data, loading } = useGetData("/followers/");
  const [followers, setFollowers] = useState({
    instagram: "19.7k",
    facebook: "1.7k",
    linkedin: "15k",
    tiktok: "4.6k",
  });

  useEffect(() => {
    window.fd("form", {
      formId: "661822e4de4d11874af026f0",
      containerEl: formContainerRef.current,
    });
  }, []);

  useEffect(() => {
    const lookup = {};
    if (!loading && data) {
      data.forEach(({ platform, count }) => {
        lookup[platform] = count;
      });
      setFollowers(lookup);
    }
  }, [loading, data]);

  return (
    <div className="footer-wrapper">
      <ul className="social-media">
        <li
          onClick={() =>
            window.open("https://www.facebook.com/LesOttolenghiCIO", "_blank")
          }
          style={{
            background: "url(/images/socialMedia/1.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#fff", lineHeight: "0" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {followers.facebook}
            </span>{" "}
            followers{" "}
          </p>
        </li>
        <li
          onClick={() =>
            window.open("https://www.instagram.com/lesottolenghiofc", "_blank")
          }
          style={{
            background: "url(/images/socialMedia/3.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#fff", lineHeight: "0" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {followers.instagram}
            </span>{" "}
            followers{" "}
          </p>
        </li>
        <li
          onClick={() =>
            window.open("https://www.linkedin.com/in/lesottolenghi/", "_blank")
          }
          style={{
            background: "url(/images/socialMedia/4.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#fff", lineHeight: "0" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {followers.linkedin}
            </span>{" "}
            followers{" "}
          </p>
        </li>
        <li
          onClick={() =>
            window.open("https://www.tiktok.com/@lesottolenghi", "_blank")
          }
          style={{
            background: "url(/images/tiktok.jpeg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            cursor: "pointer",
          }}
        >
          <p style={{ color: "#fff", lineHeight: "0" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {followers.tiktok}
            </span>{" "}
            followers{" "}
          </p>
        </li>
      </ul>
      <div ref={formContainerRef} id="flodesk-form-container" />
      <Typography variant="body2" pt={"30px"} color="white" align="center">
        {"Copyright © "} {new Date().getFullYear()}
        {" LJMO."}
      </Typography>
      <Typography pt={"8px"} color="white" variant="p" component="p">
        Built with Meta Digital Solutions
      </Typography>
      <Typography
        pt={"8px"}
        pb={"50px"}
        color="white"
        variant="p"
        component="p"
      >
        All rights reserved
      </Typography>
      <ul
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "50px 0 60px",
          marginBottom: "0",
          borderTop: "1px solid rgba(255,255,255,.18)",
        }}
      >
        {socialLinks.map(({ alt, icon, url }) => {
          return (
            <IconButton
              key={url}
              as={NavLink}
              to={url}
              target="__blank"
              size="small"
              sx={{
                color: "#CCCCCC",
                borderRadius: "100%",
                border: "1px solid rgba(255,255,255,.18)",
                width: "46px",
                height: "46px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  border: "2px solid #fff",
                },
              }}
            >
              {icon}
            </IconButton>
          );
        })}
      </ul>
    </div>
  );
};

export default Footer;
